import { Injectable } from "@angular/core";
import { from, map, Observable } from "rxjs";
import { BlobServiceClient, HttpHeaders } from '@azure/storage-blob';
import { environment } from "../../../../environments/environment";
import { SASUpload } from "../../models/interfaces/files/files.interface";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AzureBlobService {
  constructor(private readonly _http: HttpClient) {}

  public uploadBlob(blob: Blob, container: string, blobName: string): Observable<string> {
    const blobServiceClient = new BlobServiceClient(
      `https://${environment.azureBlobAccountName}.blob.core.windows.net?${environment.azureBlobSasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(container);
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Faz upload e retorna a URL do blob
    return from(blockBlobClient.uploadData(blob, { blobHTTPHeaders: { blobContentType: 'image/png' } })).pipe(
      map(() => blockBlobClient.url)
    );
  }

  public getSASToken(token: string): Observable<SASUpload> {
    return this._http.get<SASUpload>(`${environment.filesAPIUrl}/sasupload`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
  }

  public uploadFileWithSASSignature(sas: string, file: File, mimeType: string): Observable<any> {
    return this._http.put(sas, file, {
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': mimeType,
      }
    });
  }

  public uploadWithSAS(sasDetails: SASUpload, token: string, uploadDetails: { fileName: string, source: string }): Observable<{id: string; name: string;} | null> {
    const path = 'sasupload';
 
    return this._http.post<{id: string; name: string;}>(`${environment.filesAPIUrl}/${path}`, {
          Id: sasDetails.id,
          FileName: uploadDetails.fileName,
          Source: uploadDetails.source
      }, {
          headers: {
              'Authorization': `Bearer ${token}`,
          }
      });
  }
}
