import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, share, switchMap } from 'rxjs/operators';
import { IUser, UserPermissionsEnum } from '../../../shared/models/interfaces/users.interface';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<IUser | null>;
  private _helper = new JwtHelperService();
  private _userFingerprint!: string;

  constructor(private _jwtHelper: JwtHelperService, private _http: HttpClient) {
   
    const token = localStorage.getItem('token');
    this.currentUserSubject = new BehaviorSubject<IUser | null>(token ? <IUser>JSON.parse(token) : null);
  }

  public currentUserValue(token?: string): IUser | null {
    if (!token && this.currentUserSubject?.value) {
      token = this.currentUserSubject?.value['access_token'];
    }

    if (token) {
      return this._helper.decodeToken<IUser>(token) || null;
    }

    return null;
  }

  public get currentTokenValue(): any | null {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token ? !this._jwtHelper.isTokenExpired(<string>JSON.parse(token)['access_token']) : false;
  }

  private _toFormUrlEncoded(str: string): string {
    return encodeURIComponent(str)
      .replace(/[!'()*]/g, (c) => '%' + c.charCodeAt(0).toString(16))
      .replace(/%20/g, '+');
  }

  public authenticate(username: string, password: string): Observable<IUser | unknown> {
    const headers = new HttpHeaders();

    const data = `username=${this._toFormUrlEncoded(username)}&password=${this._toFormUrlEncoded(password)}&grant_type=password&client_id=${
      environment.clientId
    }&client_secret=${environment.clientSecret}`;

    const options = {
      headers: headers,
      withCredentials: false,
    };

    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    if (username.split('@nibo.com.br').length !== 2) {
      return throwError('Este login não tem permissão para acessar o sistema.');
    }

    return this._http.post<IUser>(`${environment.passportUrl}/token`, data, options).pipe(
      switchMap((user) => {
        if (user && user.access_token) {
          localStorage.setItem('token', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return of(user);
      })
    );
  }

  /**
   * Possibilita a inserção do token "on the fly".
   * Útil especialmente para web-components que dependem de serviços.
   * @param token Token de autenticação do NIBO
   */
  public injectToken(token: string): void {
    localStorage.setItem('token', JSON.stringify({ access_token: token }));
  }

  public refreshToken(): Observable<IUser> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const options = {
      headers: headers,
      withCredentials: false,
    };

    const token = this.currentTokenValue as unknown as { refresh_token: string };
    const data = `grant_type=refresh_token&client_id=${environment.clientId}&client_secret=${environment.clientSecret}&refresh_token=${token['refresh_token']}`;
    return this._http.post<any>(`${environment.passportUrl}/token`, data, options).pipe(
      share(),
      map<IUser, IUser>((user) => {
        if (user && user.access_token) {
          localStorage.setItem('token', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      })
    );
  }

  public hasPermission(feature: UserPermissionsEnum): Observable<boolean> {
    return this.currentUserSubject.pipe(
      map((user) => {
        let hasPermission = true;

        // if (user) {
        //   switch (feature) {
        //     case UserPermissionsEnum.createRobot:
        //       switch (this.currentUserValue().RobotsRole) {
        //         case UserRolesEnum.superAdmin:
        //         case UserRolesEnum.admin:
        //         case UserRolesEnum.robotsManager:
        //           hasPermission = true;
        //           break;
        //       }
        //       break;
        //   }
        // }

        return hasPermission;
      })
    );
  }

  public logout(): void {
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }
}
